<template>
  <div v-if="awardsList.length > 0" class="awardsslider-component">
    <swiper
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 20,
        stretch: 50,
        depth: 100,
        modifier: 2,
        slideShadows: true,
      }"
      class="mySwiper"
    >
      <swiper-slide
        part="swiper-slide"
        v-for="slide in awardsList"
        :key="slide.id"
        class="slide card-item"
      >
        <div class="ion-text-center slide-container">
          <div class="slide-img" @click="openModal(slide)">
            <ion-avatar>
              <img
                v-if="slide.url_image"
                :src="slide.url_image"
                @error="handleImgError"
              />
              <img v-else src="../../public/assets/img/award_placeholder.png" />
            </ion-avatar>
          </div>

          <div class="slide-title">
            <h5>{{ $root.translate(slide.title).toUpperCase() }}</h5>
          </div>
          <div class="d-flex flex-column ion-justify-content-between">
            <p v-if="$root.translate(slide.nomeCampagna).length < 30">
              Campagna: {{ $root.translate(slide.nomeCampagna) }}
            </p>
            <p v-else>
              {{ $root.translate(slide.nomeCampagna).slice(0, 30) }}...
            </p>

            <p>
              Riscattato:
              {{
                slide.riscattatoData.slice(8, 10) +
                "-" +
                slide.riscattatoData.slice(5, 7) +
                "-" +
                slide.riscattatoData.slice(0, 4)
              }}
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div v-else>
    <ion-row> <ion-col>Premi vinti non presenti</ion-col></ion-row>
  </div>

  <div v-if="showModal" :class="{ 'show-modal': showModal }" class="modal">
    <div class="modal-content">
      <div class="image">
        <ion-avatar>
          <img :src="modalData.url_image" @error="handleImgError" />
        </ion-avatar>
      </div>
      <div class="title-container">
        <h4>{{ modalData.title["it-IT"] }}</h4>
      </div>
      <div v-if="modalData.perc_completamento" class="progressbar-container">
        <common-progress-bar
          class="height-10"
          :value="modalData.perc_completamento"
        ></common-progress-bar>
      </div>
      <div class="description-container">
        <ion-row class="contenuto">
          <ion-col>
            <ion-row class="ion-padding-bottom" v-if="modalData.nomeCampagna">
              <ion-col>
                <span style="font-weight: bold"
                  >Award vinto nella campagna:</span
                >
                <br />
                <span>{{ $root.translate(modalData.nomeCampagna) }}</span>
              </ion-col>
            </ion-row>
            <ion-row v-if="modalData.description">
              <ion-col>
                {{ $root.translate(modalData.description) }}
              </ion-col>
            </ion-row>
            <ion-row class="ion-padding-bottom" v-if="modalData.vintoData">
              <ion-col>
                <span style="font-weight: bold">Award vinto:</span><br />
                <span>{{
                  modalData.vintoData.slice(8, 10) +
                  "-" +
                  modalData.vintoData.slice(5, 7) +
                  "-" +
                  modalData.vintoData.slice(0, 4) +
                  " alle " +
                  modalData.vintoData.slice(11, 19)
                }}</span>
              </ion-col>
            </ion-row>
            <ion-row class="ion-padding-bottom" v-if="modalData.riscattatoData">
              <ion-col>
                <span style="font-weight: bold">Award ricattato:</span><br />
                <span>{{
                  modalData.riscattatoData.slice(8, 10) +
                  "-" +
                  modalData.riscattatoData.slice(5, 7) +
                  "-" +
                  modalData.riscattatoData.slice(0, 4) +
                  " alle " +
                  modalData.riscattatoData.slice(11, 19)
                }}</span>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <ion-row class="ion-justify-content-center ion-margint-top">
        <ion-col size="auto">
          <ion-button @click="showModal = false"> Close </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import {
  IonAvatar,
  IonButton,
  IonModal,
  IonContent,
  IonRow,
  IonCol,
  IonicSwiper,
  modalController,
} from "@ionic/vue";
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import ModalInfoAward from "../views/Modals/ModalInfoAward.vue";

import { translate } from "../common/helper.auth";

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination]);
export default {
  name: "AwardsSlider",
  props: {
    awardsList: Array,
  },
  components: {
    IonAvatar,
    IonButton,
    IonModal,
    IonContent,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
    ModalInfoAward,
  },
  created() {
    console.log("AWARD LIST ", this.awardsList);
  },
  data() {
    return {
      currentSlide: {},
      modalData: {},
      showModal: false,
      EffectCoverflow,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    async openModal(slide) {
      this.modalData = {};

      this.showModal = true;

      this.modalData = JSON.parse(JSON.stringify(slide));

      console.log(this.modalData);

      // const modal = await modalController.create({
      //   component: ModalInfoAward,
      //   cssClass: "common-modal",
      //   componentProps: {
      //     award: slide,
      //   },
      // });
      // return modal.present();
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  width: 200px;
  height: 300px;
  margin: 30px 0;
  padding: 1rem 0.2rem;
  border-radius: 10px;
}
.slide-img {
  display: grid;
  place-content: center;
  width: auto;
  max-height: 50%;

  ion-avatar {
    width: 130px;
    height: 130px;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 10;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem 1.5rem;
  width: 80vw;
  border-radius: 0.5rem;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.image {
  display: grid;
  place-content: center;

  ion-avatar {
    width: 40vw;
    height: 40vw;
  }
}

.progressbar-container {
  padding: 3rem 0;
  width: 30vw;
  margin: auto;
  ion-progress-bar {
    border-radius: 20px;
  }
}

.description-container {
  margin-bottom: 3rem;
  font-size: 1rem;
}

.title-container {
  padding: 2rem 0 0;
  text-align: center;
}
</style>
