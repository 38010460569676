<template>
  <PolarAreaChart
    :chart-data="data"
    :options="options"
    css-classes="chart-container"
  >
  </PolarAreaChart>
</template>

<script>
import { PolarAreaChart } from "vue-chart-3";

import { hslToHex } from "../utils/hslToRgb";
import { hexToHSL } from "../utils/hexToHsl";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export default {
  name: "RisultatoSurvey",
  components: {
    PolarAreaChart,
  },
  props: {
    pallette: Array,
    labelsData: Array,
  },
  created() {
    if (this.pallette) {
      this.colors = this.pallette;
    } else {
      this.colors = this.createPallette();
    }
  },
  data() {
    return {
      options: {
        startAngle: 0,
        colors: [],
      },
    };
  },
  computed: {
    data() {
      return {
        labels: this.labelsData,
        datasets: [
          {
            label: "My First Dataset",
            data: [11, 16, 7, 3, 14],
            backgroundColor: this.colors,
          },
        ],
      };
    },
  },
  methods: {
    createPallette() {
      const numberOfColors = this.labelsData.length;

      const primaryColor = this.whappyClientConfig.primaryColor;
      const secondaryColor = this.whappyClientConfig.secondaryColor;

      const hslPrimaryColor = hexToHSL(primaryColor);
      const hslSecondaryColor = hexToHSL(secondaryColor);

      const range = (hslPrimaryColor.h - hslSecondaryColor.h) / numberOfColors;

      let hue = hslPrimaryColor.h;
      let saturation = 80;
      let lightness = 60;

      let counter = 0;

      let colors = [];
      for (counter; counter <= numberOfColors; counter++) {
        let color = hslToHex(hue, saturation, lightness);

        colors.push(color);
        if (hue + range < 360) {
          hue += range;
        } else {
          hue += range - 360;
        }
      }
      return colors;
    },
  },
};
</script>